import Splash from '@/components/Wizard/RequestWizard/Steps/Splash';
import OccasionStep from '@/components/Wizard/RequestWizard/Steps/OccasionStep';
import LocationStep from '@/components/Wizard/RequestWizard/Steps/LocationStep';
import AddressTypeStep from '@/components/Wizard/RequestWizard/Steps/AddressTypeStep';
import DateStep from '@/components/Wizard/RequestWizard/Steps/DateStep';
import GuestsStep from '@/components/Wizard/RequestWizard/Steps/GuestsStep';
import FoodStep from '@/components/Wizard/RequestWizard/Steps/FoodStep';
import BudgetStep from '@/components/Wizard/RequestWizard/Steps/BudgetStep';
import CuisineStep from '@/components/Wizard/RequestWizard/Steps/CuisineStep';
import DietStep from '@/components/Wizard/RequestWizard/Steps/DietStep';
import PreferenceStep from '@/components/Wizard/RequestWizard/Steps/PreferenceStep';
import AuthStep from '@/components/Wizard/RequestWizard/Steps/AuthStep';
import Feature from '@/utils/Features/Feature';

/**
 * Do NOT use this directly.
 * Use the `steps` helper from useSteps() hook instead.
 */

/**
 * Request Wizard Steps
 * @type {Array}
 * @property {string} name - Unique key for tracking
 * @property {string} label - Displayed label
 * @property {boolean} include - Conditionally include steps
 * @property {boolean} skippable - Optional step can skip validation
 * @property {boolean} isComplete - Mark step as complete and unlock next step
 * @property {boolean|string} error - Mark step as errored (string for error message)
 * @property {Array} variants - Feature flag variants
 * @property {React.Component} component - Step component
 * @property {boolean} hideNav - Hide navigation buttons & progress bar
 * @property {boolean} hideFromProgress - Hide from progress bar
 */

export const steps = [
    {
        name: 'splash',
        label: 'Splash',
        include: false,
        skippable: true,
        isComplete: false,
        error: false,
        variants: [],
        component: Splash,
        hideNav: true,
        hideFromProgress: true,
    },
    {
        name: 'address-type',
        label: 'Where',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: AddressTypeStep,
    },
    {
        name: 'location',
        label: 'Where',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: LocationStep,
    },
    {
        name: 'date',
        label: 'When',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: DateStep,
    },
    {
        name: 'guests',
        label: 'Who',
        include: true,
        skippable: true,
        isComplete: false,
        error: false,
        variants: [],
        component: GuestsStep,
    },
    {
        name: 'event-type',
        label: 'Event type',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: OccasionStep,
    },
    {
        name: 'food-type',
        label: 'Type of food',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: FoodStep,
    },
    {
        name: 'budget',
        label: 'Pricing',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: BudgetStep,
    },
    {
        name: 'cuisines',
        label: 'Cuisines',
        include: true,
        skippable: true,
        isComplete: false,
        error: false,
        variants: [],
        component: CuisineStep,
    },
    {
        name: 'diets',
        label: 'Dietary requirements',
        include: true,
        skippable: false,
        isComplete: false,
        error: false,
        variants: [],
        component: DietStep,
        // hideFromProgress: true,
    },
    {
        name: 'preferences',
        label: 'Preferences',
        include: true,
        skippable: true,
        isComplete: false,
        error: false,
        variants: [],
        component: PreferenceStep,
        hideFromProgress: true,
    },
    {
        name: 'auth',
        label: 'Create an account',
        include: window?.userId ? false : true,
        skippable: false,
        isComplete: window?.userId ? true : false,
        error: false,
        variants: [],
        component: AuthStep,
        hideNav: true,
        hideFromProgress: true,
    },
];
