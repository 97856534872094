import axios from 'axios';
import { toast } from '@/utils/Toast';

import {
    // API
    WIZARD_SUCCESS,

    // State update
    WIZARD_UPDATE,
} from '@/constants/Actions/RequestWizardTypes';

/**
 * MARK: API actions
 */

export const getInitData = () => (dispatch) => {
    const url = route('api.wizard.get');
    const request = axios.get(url);

    return request
        .then((response) => {
            dispatch(updateOptions(response.data));

            dispatch(
                updateMeta({
                    variant: response.data.features?.feature_wizard_q4
                        ? 'feature_wizard_q4'
                        : 'control',
                    features: response.data.features,
                    ...(response.data.features ?? {}),
                })
            );

            dispatch(updateStatus('loading', false));
        })
        .catch((error) => {
            console.error('WIZARD ERROR: ', { init: error.response });
        });
};

// Postcode lookup
export const postcodeAutoComplete = (postcode) => (dispatch) => {
    const url = route('postcode.autocomplete', { postcode });

    const request = axios.get(url);

    return request
        .then((response) => {
            dispatch(updateOptions('postcodes', response.data));
        })
        .catch((error) => {
            console.error('WIZARD ERROR: ', {
                postcodeAutocomplete: error.response,
            });
        });
};

// Check for international and valid location
export const validateLocation = (postcode) => (dispatch) => {
    const url = route('postcode.validate', { postcode });

    const request = axios.get(url);

    return request
        .then((response) => {
            dispatch(
                updateOptions({
                    isInternational: response.data.is_international,
                    isValidLocation: response.data.is_valid,
                })
            );
        })
        .catch((error) => {
            console.error('WIZARD ERROR: ', {
                validateLocation: error.response,
            });
        });
};

// Get chefs count
export const getChefsCount = () => (dispatch, getState) => {
    const { requestWizard } = getState();
    const params = {
        postcode: requestWizard?.request?.postcode,
        mealDate: requestWizard?.request?.mealDate,
        cuisines: requestWizard?.request?.cuisines,
    };

    const budget = parseFloat(requestWizard?.request?.budget) || 0;
    if (budget > 0) {
        params.price = [0, budget];
    }

    const url = route('api.wizard.chefs-count', params);
    const request = axios.get(url, params);

    return request
        .then((response) => {
            dispatch(handleSuccess('chefsCount', response.data.chefCount));
        })
        .catch((error) => {
            console.error('WIZARD ERROR: ', { chefsCount: error.response });
        });
};

// Submit request
export const submitRequest = () => (dispatch, getState) => {
    dispatch(updateStatus('submitting', true));

    const url = route('api.wizard.submit');
    const { requestWizard } = getState();
    const params = {
        guests: requestWizard?.request?.guests,
        addressType: requestWizard?.request?.addressType,
        postcode: requestWizard?.request?.postcode,
        mealDate: requestWizard?.request?.mealDate,
        budget: requestWizard?.request?.budget,
        budgetType: requestWizard?.request?.budgetType,
        includingGrocery: requestWizard?.request?.includingGrocery,
        includingBbq: requestWizard?.request?.includingBbq,
        type: requestWizard?.request?.type, // Occasion
        servingType: requestWizard?.request?.servingType, // Food
        dietaryPreferences: requestWizard?.request?.dietaryPreferences,
        foodPreferences: requestWizard?.request?.foodPreferences, // Additional comments
        cuisines: requestWizard?.request?.cuisines,
        source: requestWizard?.meta?.source,
        version: requestWizard?.meta?.version,
    };
    // const data = Object.assign({}, params);

    const request = axios.post(url, params);

    return request
        .then((response) => {
            sessionStorage.removeItem('yh-pending-request');
            sessionStorage.removeItem('redirectTo');
            dispatch(updateStatus('pending', false));

            if (!response.data.success) {
                dispatch(updateStatus('submitting', false));
                toast({
                    title: response.data.message,
                    type: 'error',
                });
                return;
            }

            // Clear the previous ecommerce object.
            dataLayer.push({ ecommerce: null });

            dispatch(updateMeta({ ...response.data }));

            setTimeout(() => {
                dispatch(
                    updateStatus({
                        // submitting: false,
                        submitted: true,
                    })
                );
            }, 2000);
        })
        .catch((error) => {
            dispatch(updateStatus({ submitting: false, pending: false }));
            console.error('WIZARD ERROR: ', { submit: error.response });
            toast({
                title: error.response.data.message,
                type: 'error',
            });
        });
};

/**
 * MARK: State update actions
 */

// Update request state
export const updateRequest = (key, value = false) => {
    return {
        type: WIZARD_UPDATE,
        payload: {
            type: 'request',
            key,
            value,
        },
    };
};

// Update meta state
export const updateMeta = (key, value = false) => {
    return {
        type: WIZARD_UPDATE,
        payload: {
            type: 'meta',
            key,
            value,
        },
    };
};

// Update status
export const updateStatus = (key, value = false) => {
    return {
        type: WIZARD_UPDATE,
        payload: {
            type: 'status',
            key,
            value,
        },
    };
};

// Update options
export const updateOptions = (key, value = false) => {
    return {
        type: WIZARD_UPDATE,
        payload: {
            type: 'options',
            key,
            value,
        },
    };
};

/**
 * MARK: Handlers
 */
export const handleSuccess = (key, payload) => {
    return {
        type: WIZARD_SUCCESS,
        payload: {
            key,
            data: payload,
        },
    };
};
